<template>
  <div class="home">
    <ch-icon v-if="active"  name="home_active" style="font-size: 22px" @click="goHome"
             @mouseover="() => {this.active=true}"
             @mouseout="() => {this.active=false}"
    ></ch-icon>
    <ch-icon v-else  name="home" style="font-size: 22px" @click="goHome"
             @mouseover="() => {this.active=true}"
             @mouseout="() => {this.active=false}"
    ></ch-icon>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "index",
  data(){
    return {
      active:false
    }
  },
  computed:{
    ...mapGetters(['routerHome']),
  },
  methods:{
    goHome(){
      this.$router.push({ path: '/classicIndex' });
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  align-self: center;
  padding-right: 15px;
  padding-top: 3px;
  cursor: pointer;
}
</style>
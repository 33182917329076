<template>
  <div class="system" v-if="system && system.length >0">
    <el-dropdown @visible-change="change">
      <span class="el-dropdown-link">
        <ch-icon v-if="active"  name="system_active" style="font-size: 24px"
        ></ch-icon>
        <ch-icon v-else  name="system" style="font-size: 24px"
        ></ch-icon>
      </span>
      <el-dropdown-menu slot="dropdown" class="system_dropdown">
        <el-dropdown-item v-for="item in system[0].children" :key="item.id">
          <span @click="goRouter(item.path)">{{item.menuName}}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "index",
  data(){
    return{
      active:false,
    }
  },
  computed:{
    ...mapGetters(['system']),
  },
  methods:{
    goRouter(name){
      this.$router.push({ path: "/"+ name });
    },
    change(val){
      this.active = val
    }
  }
}
</script>

<style lang="scss" scoped>
.system{
  display: flex;
  align-self: center;
  padding-right: 12px;
  padding-top: 5px;
  cursor: pointer;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>